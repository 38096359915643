// src/components/SignIn.js
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState, useEffect } from 'react'; 
import Footer from './Footer';
import { FaMale, FaFemale, FaComments, FaUserFriends, FaRandom, FaGlobe, FaRocketchat } from 'react-icons/fa'; // Import icons for gender
import { API_BASE_URL } from '../contexts/Constants.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { getSession, setSession } from '../services/auth'; // Import the auth functions

import './App.css'; // Import the SignIn component styles 

function SignIn({ onSignIn }) {
  // Check for existing user session on component mount
  useEffect(() => {
    const userSession = getSession();
    if (userSession) { 
      setGender(userSession.gender); 
    }
  }, []);

  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [gender, setGender] = useState('');  
  const [error, setError] = useState(null);
  const [fingerprint, setFingerprint] = useState(''); 
  const [currentStep, setCurrentStep] = useState(1); // 1: username, 2: gender, 3: age 
   
  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const clickStart = () => {
    setCurrentStep(2);
  };
 
  // Function to handle user sign-in and session management
  const handleSignIn = async (data) => {
    try {
      const { sessionId, id, name, gender, age, here, languages } = data;
      setSession({ sessionId, id, name, gender, age, here, languages });
      onSignIn({ sessionId, id, name, gender, age, here, languages }); 

      if (data.sessionId && data.id) { 
        handleGTagReportConversion();
      }
    } catch (error) {
      console.error('Failed to sign in:', error);
      setError('Failed to sign in. Please try again later.');
    }
  };
  
  // Function to call gtag_report_conversion
  const handleGTagReportConversion = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-961757397/31gZCNniuo4ZENWBzcoD'
      });
    } else {
      console.error('gtag is not defined. Ensure you have included the Google Ads gtag.js script in your application.');
    }
  };

  // Function to generate the fingerprint
  const generateFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    setFingerprint(result.visitorId);
  };

  // Generate the fingerprint when the component mounts
  useEffect(() => {
    generateFingerprint(); 
  }, []);
 
  const onSubmit = async (e, isAgeConfirmed) => {
    e.preventDefault();
  
    if (!isAgeConfirmed) {
      setGender(''); 
      setCurrentStep(1);
      return;
    } 

    if (!gender || ![1, 2].includes(Number(gender))) {
      setError("Please select a valid gender.");
      return;
    } 

    if (!recaptchaToken) {
      //setError('Please verify you are not a robot.');
      //return;
    }

    try { 
      if (!gender) {
        setError('Please fill in all fields');
        return;
      }

      const response = await fetch(`${API_BASE_URL}/signin/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ gender, fingerprint, recaptchaToken }),
      });

      if (!response.ok) { 
        setError('Failed to sign in. Please try again later.');
        return;
      }

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else if (data.exists) {
        setError(null);
      } else {
        if (data.sessionId && data.id) { 
          handleSignIn(data); 
        }
      }
    } catch (error) {
      console.error('Failed to sign in:', error);
      setError('Failed to sign in. Please try again later.'); 
    }
  };

  return (
    <div className="signin-container">
      <form onSubmit={(e) => onSubmit(e, true)}>
        <header className="header">
          <img src="logo.png" className="logo" alt="Logo" />
        </header>

        <h1>Join Random Chat with Strangers</h1>
        <div className="description">
          <p>
            Join our random chat and connect with people worldwide. Find friends, explore diverse perspectives, and enjoy spontaneous conversations with strangers from around the globe!
          </p>
        </div>
        
        {error && <div className="error-message">{error}</div>}

        {currentStep === 1 && (
          <div className="sign-nav">
            <button className="start-chatting" type="button" onClick={clickStart}>
              <FaRocketchat className="start-icon" />
              Start Chatting
            </button>
          </div>
        )}

        {currentStep === 2 && (
          <div className="input-container">
            <label>Gender:</label>
            <div className="gender-selection">
              <label className="gender-option">
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  id="genderMale"
                  checked={gender === '2'}
                  onChange={(e) => setGender(e.target.value)}
                />
                <FaMale className="gender-icon" />
                <span className="gender-label">Male</span>
              </label>
              <label className="gender-option">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  id="genderFemale"
                  checked={gender === '1'}
                  onChange={(e) => setGender(e.target.value)}
                />
                <FaFemale className="gender-icon" />
                <span className="gender-label">Female</span>
              </label>
            </div>

            <small>
              By starting the chat, you agree to our "terms and conditions" and "privacy policy".
            </small>

            <div className="sign-nav">
              {/*
              <ReCAPTCHA
                sitekey="6LeFN9soAAAAABmxBrSJSZtOrH0-384fhr_liA-G"
                onChange={onRecaptchaChange}
              />*/}
              <button className="submit-button" onClick={(e) => onSubmit(e, true)}>
                Yes, I'm 18 or older
              </button>
              <button className="submit-button no" onClick={(e) => onSubmit(e, false)}>
                No, I'm under 18
              </button>
            </div>
          </div>
        )}
      </form>

      <div className="pogichat-welcome description">
        <div className="welcome-header">
          <h2>Random Chat Rooms</h2>
        </div>
        <p>
          Looking for spontaneous conversations, meeting new people, or exploring different cultures? Our random chat rooms match your interests perfectly and connect you instantly!
        </p>
      </div>
      
      <div className="description">
        <section>
          <FaComments className="description-icon" />
          <h3>Discover Random Chat Rooms for Engaging Conversations</h3>
          <p>
            Join our lively random chat rooms and connect with people worldwide. Discuss your favorite topics, share insights, or make new friends with just a click. Our platform welcomes everyone!
          </p>
        </section>
        <section>
          <FaRandom className="description-icon" />
          <h3>Embark on an Exciting Random Chat Adventure</h3>
          <p>
            Experience the thrill of spontaneous connections with our random chat feature. Each click pairs you with someone new, offering the excitement of discovering who you'll meet next. Enjoy anonymous, dynamic conversations that broaden your horizons.
          </p>
        </section>
        <section>
          <FaUserFriends className="description-icon" />
          <h3>Chat Randomly with Strangers and Expand Your Horizons</h3>
          <p>
            Connect randomly with people around the globe and widen your social circle. Share stories, laugh together, and engage in deep conversations in a friendly environment.
          </p>
        </section>
        <section>
          <FaGlobe className="description-icon" />
          <h3>Experience Global Connections with Random International Chat</h3>
          <p>
            Our random international chat connects you with people worldwide. Break cultural barriers and engage in meaningful conversations from the comfort of your home, connecting with people from every corner of the globe.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default SignIn;
